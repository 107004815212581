<template>
  <div class="home">
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="chart-card" shadow="hover">
          <h1>Hi, 欢迎光临本站。</h1>
          <div>
            <el-text>本站不提供任何形式的注册、信息发布。纯粹的个人生活分享站。不存在任何形式的后台程序，是个纯Web站。</el-text>
          </div>
          <div>
            <el-text>本站2024年08月01日起样式改为了管理系统站的样子，只是因为本人最近开发的此模板的站点较多，比之前的MarkDown模板更便于我的维护。</el-text>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
</script>

<style lang="less"></style>